<template>
  <div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <div class="showAll">
            <CheckboxSimple fieldTarget="showAll" title="Mostrar todas" v-model="showAll" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <InputText title="Pesquisar (Enter)" :disabled="disabled" field="search" :maxLength="50" v-model="search"
            :enter="getAll" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <DateTime title="Período" :disabled="isShowAll" fieldTarget="period" format="DD/MM/YYYY" type="date"
            :range="true" :confirm="true" confirmText="Filtrar" placeholder :required="false"
            v-model="selectedPeriod" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <div class="select-option">
            <SelectOption :showButtonNext="false" :disabled="disabled || isShowAll" :initialValue="initialPeriod"
              fieldTarget="month" size="small" :width="120" :height="40" :options="options" v-model="period" />
          </div>
        </b-col>
      </b-row>
    </Molded>
    <div class="div-filter-active" v-if="isFilterActive">
      Filtro Ativo
      <span class="icon-close" @click="removeFilter()">
        <i class="icon-tag fas fa-times-circle"></i>
      </span>
    </div>
    <br />
    <TableTotalRecords :totalRecords="content.data.length" />
    <Loading type="line" :center="false" v-show="loading" />
    <div v-for="item in content.data">
      <OsPanelItem :item="item" />
    </div>
    <Modal title="Produto" :width="1000" v-show="showModal('itemOrderService')">
      <ItemsOrderServiceCreateUpdate v-show="showModal('itemOrderService')" :orderServiceId="orderServiceId" />
    </Modal>

    <PrintPreview v-if="printRent" module="Maintenance" :orderServiceId="orderServiceId" :onHideModal="closeModal" />
  </div>
</template>
<script>


import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import PrintPreview from '../../adm/document/PrintPreview.vue';

import ItemsOrderServiceCreateUpdate from '../items-order-service/ItemsOrderServiceCreateUpdate.vue'
import OsPanelItem from './OsPanelItem.vue'

import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "OsPanel",
  components: {
    Button,
    Loading,
    OsPanelItem,
    CheckboxSimple,
    Molded,
    DateTime,
    SelectOption,
    TableTotalRecords, Modal,
    InputText, ItemsOrderServiceCreateUpdate,
    PrintPreview
  },
  data() {
    return {
      filter: this.$route.params.filter,
      orderServiceId: "",
      printRent: false,
      urlGet: "/api/v1/maintenance/order-service/get-all-panel",
      content: [],
      loading: true,
      selectedPeriod: [],
      initialPeriod: "today",
      period: "today",
      showAll: false,
      search: "",
      options: [
        {
          title: "Em atraso",
          titleSize: "13",
          icon: "fa-sharp fa-regular fa-sensor-triangle-exclamation",
          value: "delayed",
        },
        {
          title: "Hoje",
          titleSize: "13",
          icon: "fa-solid fa-calendar-week",
          value: "today",
        },
        {
          title: "Semana",
          titleSize: "13",
          icon: "fa-solid fa-calendar-day",
          value: "week",
        },
        {
          title: "Mês",
          titleSize: "13",
          icon: "fa-solid fa-calendar-days",
          value: "month",
        },
        {
          title: "Ano",
          titleSize: "13",
          icon: "fa-solid fa-calendar",
          value: "year",
        },
      ],
    };
  },
  created() {
    this.showAll = this.filterStorage.showAll;
    this.selectedPeriod = this.filterStorage.selectedPeriod;
    this.initialPeriod = this.filterStorage.period;
    this.period = this.filterStorage.period;
    this.search = this.filterStorage.search;
  },
  mounted() {
    this.getAll();
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generateMoviment", ["filterStorage"]),
    disabled() {
      if (this.selectedPeriod.length > 0) return true;
      return false;
    },
    isShowAll() {
      if (this.showAll) return true;
      return false;
    },
    isFilterActive() {
      if (this.period != "today") return true;
      if (this.selectedPeriod.length > 0) return true;
      if (this.showAll) return true;

      return false;
    },

  },
  methods: {
    ...mapMutations("generic", ["openModal", "addEvent", "removeLoading"]),
    ...mapMutations("generateMoviment", ["addFilterStorage"]),
    ...mapActions("generic", ["getApi"]),
    getAll() {
      this.loading = true;
      this.content = [];
      let params = {
        url: this.urlGet,
        obj: {
          search: this.search,
          period: this.period,
          showAll: this.showAll,
          start: this.selectedPeriod[0],
          end: this.selectedPeriod[1]
        },
      };

      this.addFilterStorage({ showAll: this.showAll, selectedPeriod: this.selectedPeriod, period: this.period, search: this.search });


      this.getApi(params).then((response) => {
        this.content = response.content;
        let self = this;
        setTimeout(function () {
          self.loading = false;
          self.removeLoading(["panel"]);
        }, 500);
      });
    },
    removeFilter() {
      this.selectedPeriod = [];
      this.initialPeriod = "today";
      this.period = "today";
      this.showAll = false;
      this.search = "";
    },
    closeModal() {
      this.printRent = false;
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "updateItemOrderService") {
          this.openModal("itemOrderService");
        }

        if (event.name == "saveItemsOrderService") {
          this.getAll();
        }

        if (event.name == "printOs") {

          let self = this;
          setTimeout(function () {
            self.printRent = true;
            self.orderServiceId = event.data.orderServiceId;
            self.removeLoading(["btnPrint"]);
          }, 100);
        }
      },
      deep: true,
    },
    period: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    showAll: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    selectedPeriod: {
      handler() {
        this.getAll();
      },
      deep: true,
    },
    search: {
      handler() {
        if (this.search.length == 0)
          this.getAll();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.div-filter-active {
  margin-left: 13px;
  font-size: 12px;
  background-color: #F1BC31;
  color: white;
  margin-top: 5px;
  padding-left: 7px;
  padding-right: 5px;
  border-radius: 15px;
  width: 110px;
  cursor: pointer !important;
}

.icon-close {
  font-size: 15px;
  cursor: pointer !important;
}
</style>