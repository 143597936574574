<template>
  <div class="main">
    <div>
      <Panel module="Manutenção" title="Painel de OS" layout="large" :showFilter="false" :showSearch="false"
        :showButtons="false">
        <div slot="content-main">
          <br>
          <OsPanel />
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>

import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import OsPanel from "../../../components/modules/maintenance/os-panel/OsPanel.vue";

export default {
  name: "OsPanelView",
  components: {
    Panel,
    OsPanel
  },
};
</script>

<style scoped>
.main {
  margin-left: 120px;
  margin-top: 100px;
  margin: auto;
}
</style>