<template>
    <div>
        <div class="container-item" :style="'border-color:' + borderColorClass">
            <Molded>
                <b-row>
                    <b-col sm="8">
                        <div class="side-by-side">
                            <Badge :title="'Nº ' + item.number.toString()" type="primary" size="medium" />
                        </div>
                        <div class="side-by-side div-badge">
                            <span class="badge badge-info" v-if="item.statusItemName == 'Orçamento'">Orçamento</span>
                            <span class="badge badge-success" v-if="item.statusItemName == 'Aprovada'">Aprovada</span>
                            <span class="badge badge-danger" v-if="item.statusItemName == 'Reprovada'">Reprovada</span>
                            <span class="number-rent" v-if="item.numberRent > 0">
                                <i class="fa-solid fa-code-merge"></i>
                                Locação Nº {{ item.numberRent }}
                            </span>
                        </div>
                        <div class="div-description">
                            <b-row>
                                <b-col sm="6">
                                    <span class="link" @click="navegateTo">
                                        {{ item.identification }}
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="12">
                                    <span v-if="item.customerName">
                                        <i class="fa-solid fa-user"></i> {{ item.customerName }}
                                    </span>
                                    <span v-if="item.supplierName">
                                        <i class="fa-solid fa-screwdriver-wrench"></i> {{ item.supplierName }}
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="9">
                                    <span class="link" @click="edit()">
                                        <span>
                                            <i v-if="item.barCode" class="fas fa-box"></i>
                                            <i v-else class="fas fa-toolbox"></i>
                                            {{ item.productFullName }}
                                        </span>
                                        <span class="title" v-if="item.barCode">
                                            - Nº Patrimônio {{ item.barCode }}
                                        </span>
                                        <span class="title" v-else>
                                            - Quantidade {{ item.quantity }}
                                        </span>
                                    </span>

                                    <div class="text-description title">
                                        <small>{{ item.description }}</small>
                                    </div>
                                    <div>
                                        <span class="title"> Início: {{ item.dateStartStr }} -
                                            <b>
                                                <span class="div-return">Retorno (Previsão): {{
                                                    item.dateEndStrTitle }}</span>
                                            </b>
                                        </span>
                                    </div>
                                    <div>
                                        <span class="title title-customer">
                                            Total Cliente: {{ item.totalValueCustomer | currency }}
                                        </span>
                                        <span class="pipe">|</span>
                                        <span class="title title-supplier">
                                            Total Fornecedor: {{ item.totalValueSupplier | currency }}
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col sm="3">

                        <Toggle title="Retornado" :color="'#009183'" v-model="item.returned" :changed="changed" />

                        <br>
                        <div class="text-center">
                            <Button _key="btnPrint" type="info" title="Compartilhar" classIcon="fa-light fa-share-nodes"
                                size="small" :clicked="print" />
                        </div>

                    </b-col>
                </b-row>
            </Molded>
        </div>
    </div>
</template>

<script>

import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "OsPanelItem",
    props: ["item"],
    components: {
        Molded,
        Badge,
        Button,
        Toggle
    },
    data() {
        return {
            urlIsReturnedApi: "/api/v1/maintenance/items-os/is-returned",
        }
    },
    computed: {
        borderColorClass() {
            return this.item.returned ? 'green' : '#FF8A1B';
        },
    },
    methods: {
        ...mapActions("generic", ["putApi"]),
        ...mapMutations("generic", ["addEvent"]),
        changed() {
            let params = { url: this.urlIsReturnedApi, obj: this.item, notNotifyToast: true };
            this.putApi(params).then((response) => {
                if (!response.success) this.item.returned = true;

            });
        },
        edit() {
            this.addEvent({ name: "updateItemOrderService", data: this.item })
        },
        navegateTo() {
            this.$router.push({
                name: "orderServiceUpdate",
                params: { id: this.item.orderServiceId }
            });
        },
        print() {
            this.addEvent({ name: "printOs", data: this.item })
        }
    }
}

</script>

<style scoped>
.container-item {
    margin-top: 25px;
    border-left: 2px solid;
    border-radius: 10px;
}

.div-description {
    margin: 10px;
}

.div-return {
    font-size: 16px;
}

.link {
    color: #3f529b;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.div-badge {
    margin-left: 10px
}

.title-customer {
    color: darkblue
}

.title-supplier {
    color: red
}

.pipe {
    color: gray;
    opacity: 0.5;
}

.div-print {
    margin-top: 40px;
}

.number-rent {
    margin-left: 15px;
}
</style>